import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillPlural } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill'

export const getPolyfills = (): TSFixMe => {
  let polyfills: TSFixMe = []

  if (shouldPolyfillPlural()) {
    polyfills = [...polyfills, import('@formatjs/intl-pluralrules/polyfill')]
  }

  if (shouldPolyfillRelativeTimeFormat()) {
    polyfills = [...polyfills, import('@formatjs/intl-relativetimeformat/polyfill')]
  }

  if (shouldPolyfillLocale()) {
    polyfills = [...polyfills, import(/* webpackChunkName: "intl-locale" */ '@formatjs/intl-locale/polyfill')]
  }

  if (shouldPolyfillGetCanonicalLocales()) {
    polyfills = [...polyfills, import('@formatjs/intl-getcanonicallocales/polyfill')]
  }

  if (!('fetch' in window)) {
    // @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'what... Remove this comment to see the full error message
    polyfills = [...polyfills, import(/* webpackChunkName: "whatwg-fetch" */ 'whatwg-fetch')]
  }

  return polyfills
}
