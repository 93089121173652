/* eslint-disable @typescript-eslint/ban-ts-comment */
import 'core-js/stable'
import { getPolyfills } from './polyfills'

if (!window.Promise) {
  window.Promise = Promise
}

const addPolyfillsAndRender = (): void => {
  const polyfills = getPolyfills()

  if (polyfills.length === 0) {
    import(/* webpackChunkName: "app" */ /* webpackPreload: true */ './render')
  } else {
    const importPolyfills = async (): Promise<void> => {
      await Promise.all(polyfills)

      let localePolyfills: TSFixMe = []
      if ((Intl as TSFixMe).RelativeTimeFormat.polyfilled) {
        localePolyfills = [...localePolyfills, import('@formatjs/intl-relativetimeformat/locale-data/es')]
      }
      if ((Intl as TSFixMe).PluralRules.polyfilled) {
        localePolyfills = [...localePolyfills, import('@formatjs/intl-pluralrules/locale-data/es')]
      }

      if (localePolyfills.length > 0) {
        await Promise.all(localePolyfills)
      }

      import(/* webpackChunkName: "app" */ /* webpackPreload: true */ './render')
    }

    importPolyfills()
  }
}

const addIntlPolyfill = (): void => {
  if (!global.Intl) {
    require.ensure(['intl', 'intl/locale-data/jsonp/es-MX.js'], function (require) {
      require('intl')
      require('intl/locale-data/jsonp/es-MX.js')
      addPolyfillsAndRender()
    })
  } else {
    addPolyfillsAndRender()
  }
}

addIntlPolyfill()
